import { Component, EventEmitter, Input, Output } from '@angular/core';

export type HealthscribeBannerVariant =
  | 'instructional'
  | 'success'
  | 'dismissing';

@Component({
  selector: 'omg-healthscribe-banner-v2',
  templateUrl: './healthscribe-banner-v2.component.html',
  styleUrls: ['./healthscribe-banner-v2.component.scss'],
})
export class HealthscribeBannerV2Component {
  @Input() variant: HealthscribeBannerVariant;

  @Output() startRecording = new EventEmitter<Event>();
  @Output() viewSummary = new EventEmitter<Event>();
  @Output() dismiss = new EventEmitter<Event>();
  @Output() undoDismiss = new EventEmitter<Event>();
}
